import React from "react"
import { graphql } from "gatsby"

import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"

export const query = graphql`
  query AboutQuery {
    strapiAbout {
      strapiId
      slug
      title
      content
    }
  }
`

const About = ({ data }) => {
  const about = data.strapiAbout
  return (
    <Layout title="About">
      <div className="uk-section">
        <div id="title" className="uk-container uk-container-small">
          <h1 className="uk-align-left">{about.title}</h1>
        </div>
        <div className="uk-container uk-container-small">
          <hr />
        </div>
        <div className="uk-section">
          <div className="uk-container uk-container-small">
            <ReactMarkdown source={about.content} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About